/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import {
  _getDocumentLocale,
  _getProfileFieldAtKeyPath
} from './formatters-internal.js';


export function longDate(profile, keyPath) {
  const dateString = _getProfileFieldAtKeyPath(profile, keyPath);
  if (!dateString) {
    return '';
  }
  const parsedDateString = dateString + 'T00:00:00';
  const date = new Date(parsedDateString);
  const locale = _getDocumentLocale();
  if (!dateString) {
    return '';
  } else {
    return {
      day: date.toLocaleString(locale, {day: 'numeric'}),
      month: date.toLocaleString(locale, { month: 'long'}),
      year: date.toLocaleString(locale, {year: 'numeric'}),
    }
  }
}

export function nationalizedPhoneDisplayMobile(profile, key = 'mobilePhone') {
  if (!profile[key]) {
    return '';
  }
  const phoneNumber = parsePhoneNumberFromString(profile[key])
  return phoneNumber ? phoneNumber.formatNational() : '';
}

export function updateAnchorTargets(htmlString) {
  // Create a DOMParser
  var parser = new DOMParser();

  // Parse the HTML string into a DOM document
  var doc = parser.parseFromString(htmlString, 'text/html');

  // Find all anchor tags within the document
  var anchorTags = doc.querySelectorAll('a');

  // Loop through each anchor tag and update the target attribute to "_blank"
  anchorTags.forEach(function (anchorTag) {
    anchorTag.setAttribute('target', '_blank');
  });

  // Convert the modified DOM document back to an HTML string
  var updatedHtmlString = new XMLSerializer().serializeToString(doc);

  return updatedHtmlString;
}